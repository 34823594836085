import OpenAI from "openai";

const openai = new OpenAI({
    apiKey: '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', // 在此处替换为你的 OpenAI API 密钥
    dangerouslyAllowBrowser: true 
  });

async function create_assistant() {
    const myAssistant = await openai.beta.assistants.retrieve(
        "asst_d7Lx7PplsMyr0TO6DVAMA5kh"
    );
    return myAssistant
}

async function create_thread() {
    const emptyThread = await openai.beta.threads.create();
    return emptyThread
}
  

const ASSISTANT = await create_assistant()
const THREAD = await create_thread()

async function gpt(text) {
    const message = await openai.beta.threads.messages.create(
        THREAD.id,
        { role: "user", content: text }
        );
    let run = await openai.beta.threads.runs.create(
        THREAD.id,
        { assistant_id: ASSISTANT.id }
        );

    async function waitOnRun(run, thread) {
        // 定义一个延迟函数，使用 Promise 来创建一个延迟
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    
        // 不断轮询检查 run 的状态
        while (run.status === "queued" || run.status === "in_progress") {
            // 调用 API 获取最新的 run 状态
            run = await openai.beta.threads.runs.retrieve(
                thread.id,
                run.id
            );
    
            // 等待一段时间再进行下一次检查
            await delay(500); // 等待 0.5 秒
        }
    
        // 返回最终的 run 对象
        return run;
    }

    run = await waitOnRun(run, THREAD);

    const messages = await openai.beta.threads.messages.list(
        THREAD.id,
        {limit:10, order:"asc", after:message.id}
        );
    
    let response = null;
    for (const msg of messages.data) {
        if (msg.role === "assistant") {
            response = msg.content[0].text.value; // 提取回复内容
            break; // 找到第一个 'assistant' 消息后退出循环
        }
    }
    return response;
}

export default gpt;