import OpenAI from "openai";

// 设置 OpenAI API 密钥
const openai = new OpenAI({
  apiKey: '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', // 在此处替换为你的 OpenAI API 密钥
  dangerouslyAllowBrowser: true 
});

async function whisper(audio) {
  try {
    // 调用 OpenAI API 转录音频文件
    const transcription = await openai.audio.transcriptions.create({
      file: audio, // 替换为你的音频文件路径
      model: "whisper-1", // 使用的模型
    });

    // 输出转录结果
    // console.log(transcription.text);
    return transcription.text;
  } catch (error) {
    // 错误处理
    console.error('转录失败:', error);
    return null;
  }
}

export default whisper;
