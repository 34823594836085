import React, { useState, useRef, useEffect } from 'react';
import whisper from './whisper.js'; // 导入 whisper 函数
import gpt from './chat.js';
import TexttoAudio from './tts.js';

const AudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false); // 控制录音状态
  const [audioList, setAudioList] = useState([]); // 保存录音的音频列表
  const mediaRecorderRef = useRef(null); // 引用 MediaRecorder 实例
  const audioChunksRef = useRef([]); // 存储音频数据块
  const audioContainerRef = useRef(null); // 用于引用音频列表的容器
  const audioRef = useRef(null); // 用于引用当前播放的音频
  const lastPlayedAudioIndexRef = useRef(null); // 用于存储最后播放的音频索引

  const handleMouseDown = async () => {
    try {
      // 请求麦克风权限
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      // 创建 MediaRecorder 实例
      mediaRecorderRef.current = new MediaRecorder(stream);

      // 监听数据可用事件，将音频数据块添加到数组中
      mediaRecorderRef.current.ondataavailable = event => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      // 开始录音
      mediaRecorderRef.current.start();
      setIsRecording(true); // 更新状态为录音中
    } catch (err) {
      console.error('录音失败: ', err);
    }
  };

  const handleMouseUp = () => {
    if (isRecording) {
      // 停止录音
      mediaRecorderRef.current.stop();

      // 等待录音停止事件完成处理
      mediaRecorderRef.current.onstop = async () => {
        // 创建音频 Blob
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });

        // 将新录音添加到列表中，role设为'user'
        setAudioList(prevList => [...prevList, { audio: audioBlob, role: 'user' }]);

        // 清空音频数据块数组
        audioChunksRef.current = [];

        // 将 Blob 转换为 File 对象
        const audioFile = new File([audioBlob], "latest_audio.webm", { type: "audio/webm" });

        // 调用 whisper 函数进行转录
        let text = await whisper(audioFile);

        // 调用 gpt 函数处理文本
        let messages = await gpt(text);

        // 调用 TexttoAudio 函数将回复转换为音频
        let audio_msg_unit8array = await TexttoAudio(messages);

        // 将 Uint8Array 转换为 Blob 对象
        const assistantAudioBlob = new Blob([audio_msg_unit8array], { type: 'audio/mp3' });

        // 将 assistant 音频添加到列表中，role设为 'assistant'
        setAudioList(prevList => [...prevList, { audio: assistantAudioBlob, role: 'assistant' }]);
        
        // 转录完成后更新状态为未录音
        setIsRecording(false); 
      };
    }
  };

  // 自动播放最新的 assistant 音频
  useEffect(() => {
    if (!isRecording) { // 仅当 isRecording 为 false 时执行
      // 获取最新的 assistant 音频的索引
      const lastAssistantIndex = audioList.length - 1;
      const lastAssistantAudio = audioList[lastAssistantIndex];
      
      if (lastAssistantAudio && lastAssistantAudio.role === 'assistant' && lastAssistantIndex !== lastPlayedAudioIndexRef.current) {
        const audioUrl = URL.createObjectURL(lastAssistantAudio.audio);
        if (audioRef.current) {
          audioRef.current.src = audioUrl;
          audioRef.current.play();
          lastPlayedAudioIndexRef.current = lastAssistantIndex; // 更新已播放的音频索引
        }
      }
    }
  }, [audioList, isRecording]);

  // 确保每次渲染时都滚动到最新的录音
  useEffect(() => {
    if (audioContainerRef.current) {
      audioContainerRef.current.scrollTop = audioContainerRef.current.scrollHeight;
    }
  }, [audioList]);

  return (
    <div style={{ position: 'relative', minHeight: '100vh', paddingBottom: '70px' }}>
      {/* 显示所有录音 */}
      <div 
        ref={audioContainerRef} 
        style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          overflowY: 'auto', 
          maxHeight: 'calc(100vh - 70px)', 
          padding: '10px',
          marginBottom: '60px'  // 确保录音不会被按钮遮挡
        }}
      >
        {audioList.map((item, index) => (
          <div 
            key={index} 
            style={{ 
              display: 'flex', 
              justifyContent: item.role === 'user' ? 'flex-end' : 'flex-start', 
              marginBottom: '10px' 
            }}
          >
            <audio controls src={URL.createObjectURL(item.audio)} />
          </div>
        ))}
      </div>

      {/* 隐藏的 audio 元素用于自动播放 */}
      <audio ref={audioRef} style={{ display: 'none' }} />

      {/* 固定在页面底部的按钮 */}
      <div style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'white', padding: '10px', textAlign: 'center', boxShadow: '0 -2px 5px rgba(0,0,0,0.1)' }}>
        <button 
          onMouseDown={handleMouseDown} 
          onMouseUp={handleMouseUp}
          style={{ padding: '10px 20px', fontSize: '16px' }}
        >
          {isRecording ? '录音中...' : '按住录音'} {/* 根据录音状态更新按钮文本 */}
        </button>
      </div>
    </div>
  );
};

export default AudioRecorder;
