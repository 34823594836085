import AudioRecorder from "./components/Audio";

function App() {
  return (
    <div className="App">
      <AudioRecorder />
    </div>
  );
}

export default App;
