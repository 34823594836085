import OpenAI from "openai";
// import { ElevenLabsClient, ElevenLabs, play } from "elevenlabs";

const openai = new OpenAI({
    apiKey: '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', // 在此处替换为你的 OpenAI API 密钥
    dangerouslyAllowBrowser: true 
  });
const XI_API_KEY = "sk_b585c765481bbf7e9c95e7ca3cfa028b02c87ef47ab050dd"; // 替换为你的 XI API 密钥
const VOICE_ID = "t7M28qP03OXoWqKvImSY"; // 替换为你的 Voice ID

// async function TexttoAudio(text) {
//     const mp3 = await openai.audio.speech.create({
//         model: "tts-1",
//         voice: "alloy",
//         input: text,
//       });
    
//     // // 将返回的音频数据转换为 ArrayBuffer
//     const arrayBuffer = await mp3.arrayBuffer();
//     const uint8Array = new Uint8Array(arrayBuffer);

//     // 调用函数将音频数据发送到服务器
//   const responseAudioUint8Array = await sendAudioToServer(uint8Array);

//   return responseAudioUint8Array;
// }

// // 新增函数：使用 fetch 发送音频数据到服务器，并返回 Uint8Array 数据
// async function sendAudioToServer(audioData) {
  
//   const sts_url = `https://api.elevenlabs.io/v1/speech-to-speech/${VOICE_ID}/stream`;

//   // 构建请求头
//   const headers = {
//     "xi-api-key": XI_API_KEY
//   };

//   // 构建数据部分
//   const data = {
//     "model_id": "eleven_multilingual_v2",
//     "voice_settings": JSON.stringify({
//       "stability": 1.0,
//       "similarity_boost": 1.0,
//       "style": 0.0,
//       "use_speaker_boost": true
//     })
//   };

//   // 构建 FormData 用于发送文件和数据
//   const formData = new FormData();
//   formData.append("data", JSON.stringify(data));
//   formData.append("audio", new Blob([audioData], { type: "audio/mp3" }), "input.mp3");

//   try {
//     // 发送 POST 请求到指定 URL
//     const response = await fetch(sts_url, {
//       method: "POST",
//       headers: headers,
//       body: formData
//     });

//     if (response.ok) {
//       // 读取响应的流数据并将其转换为 Uint8Array
//       const responseBuffer = await response.arrayBuffer();
//       const responseUint8Array = new Uint8Array(responseBuffer);

//       console.log("音频流成功接收.");

//       // 返回 Uint8Array
//       return responseUint8Array;
//     } else {
//       console.error("请求失败: ", await response.text());
//       return null; // 或者根据需要返回一个空 Uint8Array
//     }
//   } catch (error) {
//     console.error("发送请求时发生错误:", error);
//     return null; // 或者根据需要返回一个空 Uint8Array
//   }
// }

// 函数将文本转换为语音
async function TexttoAudio(text) {
    const url = `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`;
  
    // 设置请求头
    const headers = {
      "Accept": "audio/mpeg",
      "Content-Type": "application/json",
      "xi-api-key": XI_API_KEY
    };
  
    // 设置请求体数据
    const data = {
      "text": text,
      "model_id": "eleven_multilingual_v2",
      "voice_settings": {
        "stability": 1.0,
        "similarity_boost": 1.0,
        "style": 0.1,
        "use_speaker_boost": true
      }
    };
  
    try {
      // 使用 fetch 发送 POST 请求
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        console.error("请求失败: ", await response.text());
        return null;
      }
  
      // 获取响应数据的 ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();
  
      // 将 ArrayBuffer 转换为 Uint8Array
      const uint8Array = new Uint8Array(arrayBuffer);
    
      // 返回 Uint8Array 音频数据
      return uint8Array;
  
    } catch (error) {
      console.error("发送请求时发生错误:", error);
      return null;
    }
  }

export default TexttoAudio;